import { defineNuxtPlugin } from '#app/nuxt'
import { LazySvgoAdd, LazySvgoArrowLeft, LazySvgoArrowRight, LazySvgoArrowleft, LazySvgoChevronDown, LazySvgoFile, LazySvgoFlash, LazySvgoLogout, LazySvgoStarunfilled, LazySvgoThrash, LazySvgoThumbsdown, LazySvgoThumbsup, LazySvgoUser } from '#components'
const lazyGlobalComponents = [
  ["SvgoAdd", LazySvgoAdd],
["SvgoArrowLeft", LazySvgoArrowLeft],
["SvgoArrowRight", LazySvgoArrowRight],
["SvgoArrowleft", LazySvgoArrowleft],
["SvgoChevronDown", LazySvgoChevronDown],
["SvgoFile", LazySvgoFile],
["SvgoFlash", LazySvgoFlash],
["SvgoLogout", LazySvgoLogout],
["SvgoStarunfilled", LazySvgoStarunfilled],
["SvgoThrash", LazySvgoThrash],
["SvgoThumbsdown", LazySvgoThumbsdown],
["SvgoThumbsup", LazySvgoThumbsup],
["SvgoUser", LazySvgoUser],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
