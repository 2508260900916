import { default as auto_45loginyg9pXpy9J9Meta } from "/opt/build/repo/pages/auto-login.vue?macro=true";
import { default as errorjRoo1dIAnVMeta } from "/opt/build/repo/pages/checkout/error.vue?macro=true";
import { default as indexz5dTd4TBP7Meta } from "/opt/build/repo/pages/checkout/index.vue?macro=true";
import { default as payment_45erroruiHPz46myXMeta } from "/opt/build/repo/pages/checkout/payment-error.vue?macro=true";
import { default as select_45planOFKrvJDt5oMeta } from "/opt/build/repo/pages/checkout/select-plan.vue?macro=true";
import { default as successtDyN495SaGMeta } from "/opt/build/repo/pages/checkout/success.vue?macro=true";
import { default as trial04Gj0F4eYwMeta } from "/opt/build/repo/pages/checkout/trial.vue?macro=true";
import { default as dashboardB0S6bZ6pqpMeta } from "/opt/build/repo/pages/dashboard.vue?macro=true";
import { default as createaYON6wSy0MMeta } from "/opt/build/repo/pages/dream-buyers/create.vue?macro=true";
import { default as indexJD4tsvXgwWMeta } from "/opt/build/repo/pages/dream-buyers/index.vue?macro=true";
import { default as _91id_93z5dmFHpM5cMeta } from "/opt/build/repo/pages/dream-buyers/loading/[id].vue?macro=true";
import { default as _91id_93ZhDKZxjNEhMeta } from "/opt/build/repo/pages/dream-buyers/single/[id].vue?macro=true";
import { default as created8eAwNm2mDMeta } from "/opt/build/repo/pages/facebook-ads/create.vue?macro=true";
import { default as _91id_93kwAhsQauJsMeta } from "/opt/build/repo/pages/facebook-ads/favourites/[id].vue?macro=true";
import { default as indexJ2U8tfFLaKMeta } from "/opt/build/repo/pages/facebook-ads/index.vue?macro=true";
import { default as _91id_93qwHh37pIfLMeta } from "/opt/build/repo/pages/facebook-ads/loading/[id].vue?macro=true";
import { default as _91id_93ML52NEZ7KzMeta } from "/opt/build/repo/pages/facebook-ads/single/[id].vue?macro=true";
import { default as createWvOTk8qx8lMeta } from "/opt/build/repo/pages/headlines/create.vue?macro=true";
import { default as _91id_93LHbkPRX0OWMeta } from "/opt/build/repo/pages/headlines/favourites/[id].vue?macro=true";
import { default as indexOo8h1Hcee5Meta } from "/opt/build/repo/pages/headlines/index.vue?macro=true";
import { default as _91id_93frRHYaRiaqMeta } from "/opt/build/repo/pages/headlines/single/[id].vue?macro=true";
import { default as createaFloZtH9hCMeta } from "/opt/build/repo/pages/hvcos/create.vue?macro=true";
import { default as _91id_93269oR0HX55Meta } from "/opt/build/repo/pages/hvcos/favourites/[id].vue?macro=true";
import { default as indext5FrKR8kMvMeta } from "/opt/build/repo/pages/hvcos/index.vue?macro=true";
import { default as _91id_93KGNf8E2T3bMeta } from "/opt/build/repo/pages/hvcos/single/[id].vue?macro=true";
import { default as indexlaFeZuYZkUMeta } from "/opt/build/repo/pages/index.vue?macro=true";
import { default as loginBXD9sq3T3sMeta } from "/opt/build/repo/pages/login.vue?macro=true";
import { default as logoutlLSqGnk5PzMeta } from "/opt/build/repo/pages/logout.vue?macro=true";
import { default as billing8Lxk0ITokmMeta } from "/opt/build/repo/pages/my-account/billing.vue?macro=true";
import { default as indexW4wwWE5wdQMeta } from "/opt/build/repo/pages/my-account/index.vue?macro=true";
import { default as new_45payment_45methodRhiqhCN2A1Meta } from "/opt/build/repo/pages/my-account/new-payment-method.vue?macro=true";
import { default as pay_45invoicetuaQPfqRPpMeta } from "/opt/build/repo/pages/my-account/pay-invoice.vue?macro=true";
import { default as payment_45methodsrkGbo0LLe8Meta } from "/opt/build/repo/pages/my-account/payment-methods.vue?macro=true";
import { default as privacy_45policyQXTlhFuZ55Meta } from "/opt/build/repo/pages/privacy-policy.vue?macro=true";
import { default as createvACX1aN6kfMeta } from "/opt/build/repo/pages/products/create.vue?macro=true";
import { default as _91id_93rfjPhNbh0FMeta } from "/opt/build/repo/pages/products/edit/[id].vue?macro=true";
import { default as indextMtonKiU6yMeta } from "/opt/build/repo/pages/products/index.vue?macro=true";
import { default as qgur7u5WFpL7Meta } from "/opt/build/repo/pages/qg.vue?macro=true";
import { default as terms_45of_45serviceIo0hlUNnc4Meta } from "/opt/build/repo/pages/terms-of-service.vue?macro=true";
import { default as createqWrdzJb3ZtMeta } from "/opt/build/repo/pages/unique-mechanisms/create.vue?macro=true";
import { default as _91id_93nAEMvm46IJMeta } from "/opt/build/repo/pages/unique-mechanisms/favourites/[id].vue?macro=true";
import { default as indexXy3eqlEAkjMeta } from "/opt/build/repo/pages/unique-mechanisms/index.vue?macro=true";
import { default as _91id_93pgg6qKbpk6Meta } from "/opt/build/repo/pages/unique-mechanisms/single/[id].vue?macro=true";
import { default as waitlistjj3I101rVxMeta } from "/opt/build/repo/pages/waitlist.vue?macro=true";
export default [
  {
    name: "auto-login",
    path: "/auto-login",
    component: () => import("/opt/build/repo/pages/auto-login.vue").then(m => m.default || m)
  },
  {
    name: "checkout-error",
    path: "/checkout/error",
    meta: errorjRoo1dIAnVMeta || {},
    component: () => import("/opt/build/repo/pages/checkout/error.vue").then(m => m.default || m)
  },
  {
    name: "checkout",
    path: "/checkout",
    meta: indexz5dTd4TBP7Meta || {},
    component: () => import("/opt/build/repo/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: "checkout-payment-error",
    path: "/checkout/payment-error",
    meta: payment_45erroruiHPz46myXMeta || {},
    component: () => import("/opt/build/repo/pages/checkout/payment-error.vue").then(m => m.default || m)
  },
  {
    name: "checkout-select-plan",
    path: "/checkout/select-plan",
    meta: select_45planOFKrvJDt5oMeta || {},
    component: () => import("/opt/build/repo/pages/checkout/select-plan.vue").then(m => m.default || m)
  },
  {
    name: "checkout-success",
    path: "/checkout/success",
    meta: successtDyN495SaGMeta || {},
    component: () => import("/opt/build/repo/pages/checkout/success.vue").then(m => m.default || m)
  },
  {
    name: "checkout-trial",
    path: "/checkout/trial",
    meta: trial04Gj0F4eYwMeta || {},
    component: () => import("/opt/build/repo/pages/checkout/trial.vue").then(m => m.default || m)
  },
  {
    name: "dashboard",
    path: "/dashboard",
    meta: dashboardB0S6bZ6pqpMeta || {},
    component: () => import("/opt/build/repo/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "dream-buyers-create",
    path: "/dream-buyers/create",
    meta: createaYON6wSy0MMeta || {},
    component: () => import("/opt/build/repo/pages/dream-buyers/create.vue").then(m => m.default || m)
  },
  {
    name: "dream-buyers",
    path: "/dream-buyers",
    meta: indexJD4tsvXgwWMeta || {},
    component: () => import("/opt/build/repo/pages/dream-buyers/index.vue").then(m => m.default || m)
  },
  {
    name: "dream-buyers-loading-id",
    path: "/dream-buyers/loading/:id()",
    meta: _91id_93z5dmFHpM5cMeta || {},
    component: () => import("/opt/build/repo/pages/dream-buyers/loading/[id].vue").then(m => m.default || m)
  },
  {
    name: "dream-buyers-single-id",
    path: "/dream-buyers/single/:id()",
    meta: _91id_93ZhDKZxjNEhMeta || {},
    component: () => import("/opt/build/repo/pages/dream-buyers/single/[id].vue").then(m => m.default || m)
  },
  {
    name: "facebook-ads-create",
    path: "/facebook-ads/create",
    meta: created8eAwNm2mDMeta || {},
    component: () => import("/opt/build/repo/pages/facebook-ads/create.vue").then(m => m.default || m)
  },
  {
    name: "facebook-ads-favourites-id",
    path: "/facebook-ads/favourites/:id()",
    meta: _91id_93kwAhsQauJsMeta || {},
    component: () => import("/opt/build/repo/pages/facebook-ads/favourites/[id].vue").then(m => m.default || m)
  },
  {
    name: "facebook-ads",
    path: "/facebook-ads",
    meta: indexJ2U8tfFLaKMeta || {},
    component: () => import("/opt/build/repo/pages/facebook-ads/index.vue").then(m => m.default || m)
  },
  {
    name: "facebook-ads-loading-id",
    path: "/facebook-ads/loading/:id()",
    meta: _91id_93qwHh37pIfLMeta || {},
    component: () => import("/opt/build/repo/pages/facebook-ads/loading/[id].vue").then(m => m.default || m)
  },
  {
    name: "facebook-ads-single-id",
    path: "/facebook-ads/single/:id()",
    meta: _91id_93ML52NEZ7KzMeta || {},
    component: () => import("/opt/build/repo/pages/facebook-ads/single/[id].vue").then(m => m.default || m)
  },
  {
    name: "headlines-create",
    path: "/headlines/create",
    meta: createWvOTk8qx8lMeta || {},
    component: () => import("/opt/build/repo/pages/headlines/create.vue").then(m => m.default || m)
  },
  {
    name: "headlines-favourites-id",
    path: "/headlines/favourites/:id()",
    meta: _91id_93LHbkPRX0OWMeta || {},
    component: () => import("/opt/build/repo/pages/headlines/favourites/[id].vue").then(m => m.default || m)
  },
  {
    name: "headlines",
    path: "/headlines",
    meta: indexOo8h1Hcee5Meta || {},
    component: () => import("/opt/build/repo/pages/headlines/index.vue").then(m => m.default || m)
  },
  {
    name: "headlines-single-id",
    path: "/headlines/single/:id()",
    meta: _91id_93frRHYaRiaqMeta || {},
    component: () => import("/opt/build/repo/pages/headlines/single/[id].vue").then(m => m.default || m)
  },
  {
    name: "hvcos-create",
    path: "/hvcos/create",
    meta: createaFloZtH9hCMeta || {},
    component: () => import("/opt/build/repo/pages/hvcos/create.vue").then(m => m.default || m)
  },
  {
    name: "hvcos-favourites-id",
    path: "/hvcos/favourites/:id()",
    meta: _91id_93269oR0HX55Meta || {},
    component: () => import("/opt/build/repo/pages/hvcos/favourites/[id].vue").then(m => m.default || m)
  },
  {
    name: "hvcos",
    path: "/hvcos",
    meta: indext5FrKR8kMvMeta || {},
    component: () => import("/opt/build/repo/pages/hvcos/index.vue").then(m => m.default || m)
  },
  {
    name: "hvcos-single-id",
    path: "/hvcos/single/:id()",
    meta: _91id_93KGNf8E2T3bMeta || {},
    component: () => import("/opt/build/repo/pages/hvcos/single/[id].vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexlaFeZuYZkUMeta || {},
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: loginBXD9sq3T3sMeta || {},
    component: () => import("/opt/build/repo/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "logout",
    path: "/logout",
    meta: logoutlLSqGnk5PzMeta || {},
    component: () => import("/opt/build/repo/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: "my-account-billing",
    path: "/my-account/billing",
    meta: billing8Lxk0ITokmMeta || {},
    component: () => import("/opt/build/repo/pages/my-account/billing.vue").then(m => m.default || m)
  },
  {
    name: "my-account",
    path: "/my-account",
    meta: indexW4wwWE5wdQMeta || {},
    component: () => import("/opt/build/repo/pages/my-account/index.vue").then(m => m.default || m)
  },
  {
    name: "my-account-new-payment-method",
    path: "/my-account/new-payment-method",
    meta: new_45payment_45methodRhiqhCN2A1Meta || {},
    component: () => import("/opt/build/repo/pages/my-account/new-payment-method.vue").then(m => m.default || m)
  },
  {
    name: "my-account-pay-invoice",
    path: "/my-account/pay-invoice",
    meta: pay_45invoicetuaQPfqRPpMeta || {},
    component: () => import("/opt/build/repo/pages/my-account/pay-invoice.vue").then(m => m.default || m)
  },
  {
    name: "my-account-payment-methods",
    path: "/my-account/payment-methods",
    meta: payment_45methodsrkGbo0LLe8Meta || {},
    component: () => import("/opt/build/repo/pages/my-account/payment-methods.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    meta: privacy_45policyQXTlhFuZ55Meta || {},
    component: () => import("/opt/build/repo/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "products-create",
    path: "/products/create",
    meta: createvACX1aN6kfMeta || {},
    component: () => import("/opt/build/repo/pages/products/create.vue").then(m => m.default || m)
  },
  {
    name: "products-edit-id",
    path: "/products/edit/:id()",
    meta: _91id_93rfjPhNbh0FMeta || {},
    component: () => import("/opt/build/repo/pages/products/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: "products",
    path: "/products",
    meta: indextMtonKiU6yMeta || {},
    component: () => import("/opt/build/repo/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: "qg",
    path: "/qg",
    meta: qgur7u5WFpL7Meta || {},
    component: () => import("/opt/build/repo/pages/qg.vue").then(m => m.default || m)
  },
  {
    name: "terms-of-service",
    path: "/terms-of-service",
    meta: terms_45of_45serviceIo0hlUNnc4Meta || {},
    component: () => import("/opt/build/repo/pages/terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: "unique-mechanisms-create",
    path: "/unique-mechanisms/create",
    meta: createqWrdzJb3ZtMeta || {},
    component: () => import("/opt/build/repo/pages/unique-mechanisms/create.vue").then(m => m.default || m)
  },
  {
    name: "unique-mechanisms-favourites-id",
    path: "/unique-mechanisms/favourites/:id()",
    meta: _91id_93nAEMvm46IJMeta || {},
    component: () => import("/opt/build/repo/pages/unique-mechanisms/favourites/[id].vue").then(m => m.default || m)
  },
  {
    name: "unique-mechanisms",
    path: "/unique-mechanisms",
    meta: indexXy3eqlEAkjMeta || {},
    component: () => import("/opt/build/repo/pages/unique-mechanisms/index.vue").then(m => m.default || m)
  },
  {
    name: "unique-mechanisms-single-id",
    path: "/unique-mechanisms/single/:id()",
    meta: _91id_93pgg6qKbpk6Meta || {},
    component: () => import("/opt/build/repo/pages/unique-mechanisms/single/[id].vue").then(m => m.default || m)
  },
  {
    name: "waitlist",
    path: "/waitlist",
    meta: waitlistjj3I101rVxMeta || {},
    component: () => import("/opt/build/repo/pages/waitlist.vue").then(m => m.default || m)
  }
]