export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"},{"property":"og:image","content":"https://getkong.ai/img/og-image.jpg"},{"property":"og:image:width","content":"1200"},{"property":"og:image:height","content":"630"},{"name":"twitter:image","content":"https://getkong.ai/img/og-image.jpg"},{"name":"description","content":"A language model trained by $200 million in ad spend. This little bad boy writes copy that hits harder than a bull with its balls in a bear trap. You're gonna need a bigger piggy bank. "},{"hid":"robots","name":"robots","content":"index, follow"}],"link":[],"style":[],"script":[{"innerHTML":"\n                    (function() {\n                        if (localStorage.darkMode === 'true' || (!('darkMode' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches) || !('darkMode' in localStorage)) {\n                            document.documentElement.classList.add('dark');\n                        } else {\n                            document.documentElement.classList.remove('dark')\n                        }\n                        \n                        if(window.location.pathname === '/checkout/'){\n                            document.documentElement.classList.remove('dark')\n                        }\n                    })();\n                ","type":"text/javascript"}],"noscript":[]}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000